import { EnumFormatRank } from 'models/customer'

export enum EnumCustomerImportCategory {
  ALL = 'all',
  VALID = 'valid',
  INVALID = 'invalid'
}
export interface ImportStateEntity {
  file?: File
  is_preview?: boolean
  customers?: ImportCustomerEntity[]
  category?: EnumCustomerImportCategory
}
export interface ImportCustomerEntity {
  index: number
  name: string
  code: string
  fm_rank: EnumFormatRank
  gender: EnumGender
  store_id: string
  store_name: string
  area_id?: string
  score_amount?: number
  score_expire?: Date
  phone: string
  birthday: Date
  import_by: string
}

export enum EnumGender {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}
