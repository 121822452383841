import { CampaignEntity } from './campaign'
import { EntityBase } from './entity'
import { StoreEntity } from './store'

export enum EnumFormatRank {
  CHAMPION_1 = '01 Champion',
  LOYAL_2 = '02 Loyal',
  NEEDS_ATTENTION_3 = '03 Needs attention',
  POTENTIAL_LOYALIST_4 = '04 Potential Loyalist',
  AT_RISK_6 = '06 At Risk',
  HIBERNATING_CUSTOMERS = '08 Hibernating customers',

  GOLD = 'Vàng',
  DIAMOND = 'Kim cương',
  ROYAL = 'Hoàng Kim',
  SILVER = 'Bạc',

  UNKNOWN = 'Không xác định'
}

export const FormatRankLabel: Record<EnumFormatRank, string> = {
  '01 Champion': 'Hạng 1 - Champion',
  '02 Loyal': 'Hạng 2 - Loyal',
  '03 Needs attention': 'Hạng 3 - Cần để ý',
  '04 Potential Loyalist': 'Hạng 4 - KH tiềm năng',
  '06 At Risk': 'Hạng 6 - At risk',
  '08 Hibernating customers': 'Hạng 8 - KH cũ',
  Vàng: 'Vàng',
  'Kim cương': 'Kim cương',
  'Hoàng Kim': 'Hoàng Kim',
  Bạc: 'Bạc',
  'Không xác định': 'Không xác định'
}
export enum EnumCustomerGender {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}
export interface CustomerEntity extends EntityBase {
  name: string

  fm_rank?: EnumFormatRank
  score_amount?: number
  score_expire?: string
  phone: string
  birthday: string
  odoo_code: string
  gender: EnumCustomerGender
  store_odoo_code: string
  store?: StoreEntity
  campaigns?: CampaignEntity[]
  slug: string
}

export const CustomerGenderLabel: Record<EnumCustomerGender, string> = {
  [EnumCustomerGender.Female]: 'Nữ',
  [EnumCustomerGender.Male]: 'Nam',
  [EnumCustomerGender.Unknown]: ''
}
